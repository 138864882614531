import { Component } from '@zento-lib/components';

import { Account } from '../../Account';
import { Login } from '../../../../molecule/Auth/Login/Login';
import { Register } from '../../../../molecule/Auth/Register/Register';
import { ForgotPassword } from '../../../../molecule/Auth/ForgotPassword/ForgotPassword';
import { HeaderItem } from '../../../../atom/HeaderItem/HeaderItem';
import { Overlay } from '../../../../atom/Overlay/Overlay';

import style from './style.scss';

/**
 * Account 2
 *
 * Account 2 allowing to show signup in modal.
 **/
@Component({})
export class Account2 extends Account {
  /**
   * Open my account drawer
   */
  goToAccount() {
    if (this.currentUser) {
      this.toggleMyAccount();
    } else {
      this.$store.commit('myAccount/setDrawerActive', true);
      this.$store.commit('ui/setOverlay', false);
      this.$data.showOverlay = true;
      this.$store.commit('ui/setAuthElem', 'login');
    }
  }

  /**
   * Close my account drawer
   */
  closeDrawer() {
    this.$store.commit('myAccount/setDrawerActive', false);
    this.$store.commit('ui/setOverlay', false);
    this.$data.showOverlay = false;
    document.body.style.overflow = 'visible';
  }

  /**
   * Open my account drawer
   */
  protected toggleMyAccount() {
    this.$store.dispatch('myAccount/toggleMyAccount');
    this.$store.commit('ui/setOverlay', false);
    this.$data.showOverlay = this.isMyAccountOpen;
  }

  beforeMount() {
    this.onBroadcast('reviews-drawer', () => (this.$data.showOverlay = true));
  }

  beforeDestroy() {
    this.onBroadcast('reviews-drawer', () => (this.$data.showOverlay = false));
  }

  render() {
    return (
      <div>
        <HeaderItem
          label={this.headerItemLabel}
          icon={this.icon}
          onPress={this.goToAccount}
          onEscape={this.closeDrawer}
          testId='account-icon'
          key='account-icon'
        />

        {(this.drawerActive || this.isMyAccountOpen) && this.$data.showOverlay ? (
          <div onClick={this.closeDrawer} class={style.showOverlay} key='account-overlay'>
            <Overlay />
          </div>
        ) : null}

        <transition
          name='slide-in-right'
          enterActiveClass={style.slideInRightEnterActive}
          leaveActiveClass={style.slideInRightLeaveActive}>
          {this.drawerActive ? (
            <div class={style.myAccount}>
              <header class={style.myAccountTitle} key='header'>
                <h2>{this.getTranslation({ id: Account.T.titleAccountDrawer })}</h2>
                <span class={style.myAccountClose} onClick={this.closeDrawer} />
              </header>
              <div class={style.navigation} key='navigation'>
                <span
                  class={{
                    [style.navBtn]: true,
                    [style.activeBtn]: this.activeElement === 'login',
                  }}
                  onClick={this.switchElemLogin}
                  data-testid='loginSection'
                  key='sign-in'>
                  {this.getTranslation({ id: Account.T.signInLabel })}
                </span>
                <span
                  class={{
                    [style.navBtn]: true,
                    [style.activeBtn]: this.activeElement === 'register',
                  }}
                  onClick={this.switchElemRegister}
                  data-testid='createAccountSection'
                  key='create-account'>
                  {this.getTranslation({ id: Account.T.createAccountLabel })}
                </span>
              </div>

              {this.activeElement === 'login' ? (
                <Login styleType='drawer' data-testId='Login' key='login' />
              ) : this.activeElement === 'register' ? (
                <Register styleType='drawer' data-testId='Register' key='Register' />
              ) : null}
            </div>
          ) : null}
        </transition>

        <transition
          name='fade-in-up'
          enterActiveClass={style.fadeInUpEnterActive}
          leaveActiveClass={style.fadeInUpLeaveActive}>
          {this.activeElement === 'forgot-pass' && this.drawerActive ? (
            <ForgotPassword
              class={style.forgotPassword}
              styleType='drawer'
              data-testId='ForgotPassword'
              key='ForgotPassword'
            />
          ) : null}
        </transition>
      </div>
    );
  }
}
